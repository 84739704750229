import React, { memo } from "react";
import BackboneLogo from "../../../assets/backbone-logo.svg";
import { useNavigate } from "react-router";
import goldStar from "../../../assets/CustomIcons/goldStar.png";
import silverStar from "../../../assets/CustomIcons/silverStar.png";
import bronzeStar from "../../../assets/CustomIcons/bronzeStar.png";

const HomeCardThree = ({ topFiveBacked,badgeTier}) => {
    
    const navigate = useNavigate();
    return (
        <div className="w-100 pe-0 pe-md-4">
            <div>
                <h2 className="fs-4 fw-bolder text-center">
                    Top 5 Most Backed
                </h2>
            </div>
            <div className="my-3">
                {topFiveBacked?.length > 0 ?
                    topFiveBacked?.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="my-1"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <div className="d-flex justify-content-start align-items-center">
                                    <div
                                        className="fs-5 fw-bolder"
                                    >
                                        {index + 1}.
                                    </div>
                                    <div className="ms-2">
                                        <img
                                            style={{
                                                minWidth: "35px",
                                                height: "35px",
                                                borderRadius: "100%",
                                            }}
                                            src={
                                                item?.company_logo
                                                    ? item?.company_logo
                                                    : "https://bizbackbone-stage.s3.ap-southeast-2.amazonaws.com/defaultlogo.svg"
                                            }
                                        />
                                    </div>
                                    <div
                                        className="ms-2 company_name_column hover-primary cursor-pointer"
                                        onClick={() => {
                                            navigate(`/company/${item?.id}`);
                                        }}
                                    >
                                        <h4>{item?.company_name}</h4>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        
                                        {index == 0 || index == 1 ? (
                                            <img
                                                style={{
                                                    minWidth: "35px",
                                                    height: "35px",
                                                    borderRadius: "100%",
                                                }}
                                                src={  badgeTier?.find((item2)=>item?.backing_count>=item2?.min_backings && item?.backing_count<=item2?.max_backings)?.tier_name=="Bronze"?bronzeStar:
                                                    badgeTier?.find((item2)=>item?.backing_count>=item2?.min_backings && item?.backing_count<=item2?.max_backings)?.tier_name=="Silver"?silverStar:goldStar
                                              }
                                            />
                                        ) : index == 2 ? (
                                            <img
                                                style={{
                                                    minWidth: "35px",
                                                    height: "35px",
                                                    borderRadius: "100%",
                                                }}
                                                src={
                                                    badgeTier?.find((item2)=>item?.backing_count>=item2?.min_backings && item?.backing_count<=item2?.max_backings)?.tier_name=="Bronze"?bronzeStar:
                                                     badgeTier?.find((item2)=>item?.backing_count>=item2?.min_backings && item?.backing_count<=item2?.max_backings)?.tier_name=="Silver"?silverStar:goldStar
                                                }
                                            />
                                        ) : (
                                            <img
                                                style={{
                                                    minWidth: "35px",
                                                    height: "35px",
                                                    borderRadius: "100%",
                                                }}
                                                src={  badgeTier?.find((item2)=>item?.backing_count>=item2?.min_backings && item?.backing_count<=item2?.max_backings)?.tier_name=="Bronze"?bronzeStar:
                                                    badgeTier?.find((item2)=>item?.backing_count>=item2?.min_backings && item?.backing_count<=item2?.max_backings)?.tier_name=="Silver"?silverStar:goldStar
                                              }
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        );
                    }):
                    <h2  className="my-3 text-center fw-bold">No records found</h2>
                }
            </div>
        </div>
    );
};

export default memo(HomeCardThree);
