import React, { useMemo,useState, useEffect } from "react";
import { io }  from 'socket.io-client';
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import { RxCross1 } from "react-icons/rx";
import { FaInfoCircle } from "react-icons/fa";
import { useToast } from "@chakra-ui/react";
import Modal from "@material-tailwind/react/Modal";
import ModalHeader from "@material-tailwind/react/ModalHeader";
import ModalBody from "@material-tailwind/react/ModalBody";
import BackboneLogo from "../../assets/backbone-logo.svg";

import referafriend from "../../assets/refer.svg";
import events from "../../assets/events.svg";
import getInTouch from "../../assets/getintouch.svg";
import star from "../../assets/starnoti.svg";
import subscriptionCredit from "../../assets/subscriptionCredit.svg";
import "./Notifications.css";
import ArrowIcon from "../../assets/Arrow-icon.svg";
import { allNotificationApi, updateNotificationApi } from "../../data/api";

import triangle from "../../assets/triangle.png";


import notification, {
    resetCount,
    updateCount,
    addNotifications,
} from "../../data/reducers/notification/notification"
import { eventActions } from "../../data/reducers/events/events";
import { backedBizBucks, getBizBucksByConfigDetailsData } from "../../data/reducers/home/content";

import { API_END_POINT } from "../../config/api";
const socket = io(API_END_POINT);



const notificationIcons = {
    getInTouch: getInTouch,
    events: events,
    referFriend: referafriend,
    subscriptionCredit: subscriptionCredit,
    review: star,
};
const ArrowImg = styled.img`
    display: none;
    @media (max-width: 770px) {
        display: block;
        max-width: 20px;
    }
`;
function formatEventDate(dateString) {
    const date = new Date(dateString);

    // Month names
    const monthNames = [
        "January", "February", "March", "April", "May", "June", "July",
        "August", "September", "October", "November", "December"
    ];

    // Extracting parts of the date
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    
    // Extracting hours and minutes
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // Formatting hours for 12-hour format
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes; // Padding minutes

    // Constructing the final formatted date
    const formattedDate = `${month} ${day} @ ${hours}:${minutes} ${ampm}`;

    return formattedDate;
}

const Notifications = () => {
    const toast = useToast();
    const [Notification, setNotification] = useState([]);
    const [initialState,setInitialState]=useState(0)
    const [endState,setEndState]=useState(10)

    const { notifications } = useSelector((state) => state.notification);
    const {
        myCompany
    } = useSelector((state) => state.content);
    const stableMyCompany = useMemo(() => myCompany, [myCompany]);

    const [activePage,setActivePage]=useState(1)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [visible,setVisible]=useState(false)
    const [visible2,setVisible2]=useState(false)
    const {
        bizBucksConfig
    } = useSelector((state) => state.content);
    const [backedBizData,setBackedBizData]=useState({
        backed_id:undefined,
        backed_company_id:undefined,
        interaction_type:"",
        free_text:"",
        backType:"BACK"
    })
    

  // const sendMessage = (e) => {
  //   e.preventDefault();
  //   socket.emit('message', message);
  //   setMessage('');
  // };
    
    // useEffect(() => {
    //     console.log(socket,">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
    //      socket.on('notification', (message) => {
    //         console.log("message","socketmessage received",message)
    //      })
    //       //setMessages([...messages, message]);
    //     },[socket]);
        // setNotification(notifications?.rows);
    

    useEffect(() => {
        if (!bizBucksConfig?.length) dispatch(getBizBucksByConfigDetailsData());
    }, [dispatch,bizBucksConfig]);

    useEffect(() => {
        // updateNotification();
    }, [Notification]);
    useEffect(() => { 
        try{
            // Check if myCompany is available before setting up the listener
            if (stableMyCompany && stableMyCompany?.User && stableMyCompany?.User?.id) {
              const handleNotification = (data) => {
                let oldNotification=[]
                if(notifications?.notificationList?.rows?.length>0){
                    oldNotification=[...notifications.notificationList.rows]
                }
                 if(Array?.isArray(data)){
                      console.log(data?.filter((item)=>item?.user_id==stableMyCompany?.User?.id),"filter data>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
                      let requiredData=data?.filter((item)=>item?.user_id==stableMyCompany?.User?.id)
                      let allNotification=[
                        ...requiredData,
                        ...oldNotification
                      ]
                      
                      let newData={
                        success:notifications?.success,
                        notificationList:{
                            count:(allNotification?.length),
                            rows:allNotification
                        }
                      }
                    //   console.log(newData,"new data if array >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>")
                      if(newData){
                        dispatch(addNotifications(newData));
                      }
                 }else{
                    if(stableMyCompany?.User?.id == data?.user_id){
                          let allNotification=[]
                          if(oldNotification?.length>0){
                            allNotification=[
                                data,
                                ...oldNotification
                              ]
                          }else{
                            allNotification=[
                                data
                              ]
                          }
                          let newData={
                            success:notifications?.success,
                            notificationList:{
                                count:(allNotification?.length),
                                rows:allNotification
                            }
                          }
                          if(newData){
                            dispatch(addNotifications(newData));
                          }
                    }else{
                        console.log("else inside else")
                    }
                    
                 }
              }
        
              socket.on('notification', handleNotification);
              socket.on('connect_error', (err) => {
                console.error('Connection Error:', err);
              });
          
              socket.on('error', (err) => {
                console.error('WebSocket Error:', err);
              });
              // Clean up the listener on component unmount
              return () => {
                socket.off('notification', handleNotification);
              };
            }else{
                console.log("Not Entered")

            }
        }catch(err){
            console.log("catch block")

        }
       
    }, []); // Add dependencies if `myCompany` or `socket` can change    

    useEffect(() => {
        getAllNotification(`?page=${activePage}`);
        let count = 0;
        // Notification.map((n) => {
        //     if (!n.seen) {
        //         count += 1;
        //     }
        // });

        // if (count === 0) {
        //     dispatch(resetCount());
        // } else {
        //     dispatch(updateCount(count));
        // }
    }, [dispatch,activePage]);

    const getAllNotification = async (search) => {
        try {
            const response = await allNotificationApi(search);
            if (response?.data) {
                let data = response?.data;
                dispatch(addNotifications(data));
                // setNotification(data?.notificationList?.rows);
            }
            // console.log(response);
        } catch (err) {
            console.log(err);
        }
    };

    // const updateNotification = async () => {
    //     try {
    //         Notification.forEach(async (notification) => {
    //             if (!notification.seen) {
    //                 await updateNotificationApi({
    //                     id: notification.id,
    //                     query: { seen: true },
    //                 });
    //             }
    //         });
    //         dispatch(resetCount());
    //     } catch (err) {
    //         console.log(err);
    //     }
    // };


    const updateNotificationSeenStatus=async (item)=>{
        if (!item?.seen) {
            let response=await updateNotificationApi({
                id: item?.id,
                query: { seen: true },
            })
            if(response?.data?.message){
                getAllNotification(`?page=${activePage}`)
            }
        }
    }

    
    const handleSubmitBackedBiz=async ()=>{
        if(!backedBizData?.backed_id || !backedBizData?.backed_company_id || !backedBizData?.interaction_type?.trim()){
            console.log(backedBizData)
            toast({
                title: "All fields are required",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            })
            return
        }
        else if(backedBizData?.interaction_type=="Other: Enter Free Text (200 max)" && !backedBizData?.free_text?.trim()){
            toast({
                title: "All fields are required",
                position: "top-right",
                duration: 3000,
                status: "error",
                isClosable: true,
            })
            return
        }else{
               let response=await dispatch(backedBizBucks(backedBizData))
               if(response?.payload?.success){
                    setVisible2(true)  
                    setVisible(false)
                    setBackedBizData({
                    backed_id:undefined,
                    backed_company_id:undefined,
                    interaction_type:"",
                    free_text:"",
                    backType:"BACK"
                    })
               }else{
                toast({
                    title: `${response?.payload?.message}`,
                    position: "top-right",
                    duration: 3000,
                    status: "error",
                    isClosable: true,
                })
               }
        }
    }

    return (
        <div className="noti-container">
            <div>
                <div className="noti-headings">
                    <ArrowImg
                        src={ArrowIcon}
                        onClick={() => navigate("/?page=1")}
                    />

                    <p className="noti-heading-title">Notifications</p>
                </div>
                <p className="noti-heading-sub-title">
                    Check your email for messages and further communications.
                </p>
            </div>
            <div className="container">
                {/* {Notification?.rows?.map((notification) => {
                    return (
                        <div
                            className="noti-category border-bot"
                            style={{
                                backgroundColor: notification?.seen
                                    ? ""
                                    : "#01579B0D",
                            }}
                        >
                            <div className="icon">
                                <img
                                    src={
                                        notificationIcons[
                                            `${notification.type}`
                                        ]
                                    }
                                    alt=""
                                />
                            </div>
                            <div className="title">
                                {notification.description}
                            </div>
                            <div className="flex flex-[1_0_0] time">
                                {moment(notification.createdAt).format("ll")}
                            </div>
                        </div>
                    );
                })} */}
                 {
                    notifications?.notificationList?.rows?.filter((item)=>!item?.seen)?.length>0 && <div className="new_notification_container">
                            <span className="new_notification_tag">{notifications?.notificationList?.rows?.filter((item)=>!item?.seen)?.length}</span>
                            <span className="fw-bolder fs-3">New</span>
                 </div>
                 }

                {
                    notifications?.notificationList?.rows?.map((item,index)=>{
                        return   !item?.seen && item?.type=="backing" ? <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                        <div className="col-8">
                                            <div className="">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div >
                                                        <img style={{width:"30px",height:"30px",borderRadius:"100%"}} src={item?.companyDetails?.company_logo?item?.companyDetails?.company_logo:BackboneLogo}/>
                                                    </div>
                                                    {item?.description && <h3 className="fs-4 fw-bold ms-2">{item?.description}</h3>}
                                                </div>
                                                {item?.backingDetails && <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                       
                                                        <div className="">
                                                            {
                                                                item?.backingDetails?.interaction_type=="Met at an event & highly rate" &&  <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/9499/9499879.png"/>
                                                            }
                                                            {
                                                                item?.backingDetails?.interaction_type=="Partnered together & highly rate" &&  <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/16359/16359431.png"/>
                                                            }
                                                             {
                                                                item?.backingDetails?.interaction_type=="Outstanding Partnership Outcome" &&  <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/17437/17437670.png"/>
                                                            }
                                                            {
                                                                item?.backingDetails?.interaction_type=="Other: Enter Free Text (200 max)" &&  <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/12865/12865989.png"/>
                                                                
                                                            }
                                                            
                                                        </div>
                                                        <h3 className="text-primary fw-bold fs-4 ms-4 text-center">
                                                            {
                                                                item?.backingDetails?.interaction_type=="Other: Enter Free Text (200 max)" ? item?.backingDetails?.free_text : item?.backingDetails?.interaction_type
                                                            }
                                                        </h3>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="col-4" style={{display:"flex",justifyContent:"",alignItems:"flex-end",flexDirection:"column"}}>
                                            <div className="mt-2">
                                                <div>
                                                    <h3 className="text-success fw-bolder ms-auto text-end fs-4">+   {
                                                        bizBucksConfig?.find((item)=>item?.configKey=="back_a_business")?.configValue
                                                    } BizBucks</h3>
                                                </div>
                                                <div className="my-2 py-3 w-100">
                                                    <span className="cursor-pointer" style={{background:"blue",color:"white",padding:"5px 10px",borderRadius:"30px"}} onClick={()=>{
                                                        if(item?.backingDetails?.backer_company_id){
                                                            navigate(`/company/${item?.backingDetails?.backer_company_id}`)
                                                        }
                                                    }}>See Review</span>
                                                    <span className="ms-2 cursor-pointer" style={{background:"blue",color:"white",padding:"5px 10px",borderRadius:"30px"}} onClick={()=>{
                                                        setBackedBizData({
                                                            ...backedBizData, backed_id:item?.backingDetails?.backer_id,
                                                            backed_company_id:item?.backingDetails?.backer_company_id,
                                                        })
                                                        setVisible(true)
                                                    }}>Back Them</span>
                                                </div>
                                            </div>
                                            <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                                updateNotificationSeenStatus(item)
                                            }}>
                                                <RxCross1 style={{transform:"scale(1.2)"}}/>
                                            </span>
                                        </div>
                                 </div> :
                                (!item?.seen && (item?.type=="podcast_confirm" || item?.type=="spotlight_confirm")) ?
                                        <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px",cursor:"pointer"}} onClick={()=>{
                                            updateNotificationSeenStatus(item)
                                            navigate("/upcoming-events")
                                        }}>
                                                <div className="col-12">
                                                    <div className="">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                            <h3 className="fs-4 fw-bold me-2 text-success">
                                                                {
                                                                    item?.type=="podcast_confirm" ? "Podcast Confirm" : "Spotlight Confirm"
                                                                }
                                                            </h3>
                                                        </div>
                                                        <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                                <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                                </span>
                                                                {item?.description && <div className="ms-3">
                                                                   {item?.description}
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                                       updateNotificationSeenStatus(item)
                                                    }}>
                                                        <RxCross1 style={{transform:"scale(1.2)"}}/>
                                                    </span>
                                                </div>
                                        </div>:
                                (!item?.seen && (item?.type=="podcast_waitlist" || item?.type=="spotlight_waitlist")) ?
                                        <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                                <div className="col-12">
                                                    <div className="">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                                <h3 className="fs-4 fw-bold me-2 text-success">
                                                                    {
                                                                        item?.type=="podcast_waitlist" ? "Podcast Waitlist" : "Spotlight Waitlist"
                                                                    }
                                                                </h3>
                                                            </div>
                                                            <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                                    <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                                    </span>
                                                                    {item?.description && <div className="ms-3">
                                                                    {item?.description}
                                                                    </div>}
                                                            </div>
                                                    </div>
                                                    <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                                        updateNotificationSeenStatus(item)
                                                    }}>
                                                        <RxCross1 style={{transform:"scale(1.2)"}}/>
                                                    </span>
                                                </div>
                                        </div>:
                                (!item?.seen && (item?.type=="podcast_request" || item?.type=="spotlight_request")) ?
                                        <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                                <div className="col-12">
                                                    <div className="">
                                                        <div className="d-flex justify-content-start align-items-center">
                                                            <h3 className="fs-4 fw-bold me-2 text-primary">
                                                                {
                                                                        item?.type=="podcast_request" ? "Podcast Request" : "Spotlight Request"
                                                                    }
                                                            </h3>
                                                        </div>
                                                        <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                                <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                                </span>
                                                                {item?.description && <div className="ms-3">
                                                                    {item?.description}
                                                                </div>}
                                                        </div>
                                                    </div>
                                                    <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                                        updateNotificationSeenStatus(item)
                                                    }}>
                                                        <RxCross1 style={{transform:"scale(1.2)"}}/>
                                                    </span>
                                                </div>
                                        </div>: 
                                (!item?.seen && item?.type=="earn_reward") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px",cursor:"pointer"}} onClick={()=>{
                                    updateNotificationSeenStatus(item)
                                    navigate("/dashboard")
                                }}>
                                            <div className="col-12">
                                                <div className="">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h3 className="text-success fs-4 fw-bold me-2">BizBucks Earn</h3>
                                                    </div>
                                                    <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                            <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                            </span>
                                                            {item?.description && <div className="ms-3">
                                                                {item?.description}
                                                            </div>}
                                                    </div>
                                                </div>
                                                <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                                    updateNotificationSeenStatus(item)
                                                }}>
                                                    <RxCross1 style={{transform:"scale(1.2)"}}/>
                                                </span>
                                            </div>
                                       </div>:
                                (!item?.seen && item?.type=="referFriend") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                    <div className="col-12">
                                        <div className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <h3 className="text-success fs-4 fw-bold me-2">Refer Freinds</h3>
                                            </div>
                                            <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                    <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                    </span>
                                                    {item?.description && <div className="ms-3">
                                                        {item?.description}
                                                    </div>}
                                            </div>
                                        </div>
                                        <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                            updateNotificationSeenStatus(item)
                                        }}>
                                            <RxCross1 style={{transform:"scale(1.2)"}}/>
                                        </span>
                                    </div>
                                </div>:
                                 (!item?.seen && item?.type=="subscriptionCredit") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                    <div className="col-12">
                                        <div className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <h3 className="text-success fs-4 fw-bold me-2">Subscription Credit</h3>
                                            </div>
                                            <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                    <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                    </span>
                                                    {item?.description && <div className="ms-3">
                                                        {item?.description}
                                                    </div>}
                                            </div>
                                        </div>
                                        <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                            updateNotificationSeenStatus(item)
                                        }}>
                                            <RxCross1 style={{transform:"scale(1.2)"}}/>
                                        </span>
                                    </div>
                                </div>:

                                (!item?.seen && item?.type=="event_registration_update_by_admin") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                <div className="col-12">
                                    <div className="">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <h3 className="text-success fs-4 fw-bold me-2">Event Registration</h3>
                                        </div>
                                        <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                </span>
                                                {item?.description && <div className="ms-3">
                                                    {item?.description}
                                                </div>}
                                        </div>
                                    </div>
                                    <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                        updateNotificationSeenStatus(item)
                                    }}>
                                        <RxCross1 style={{transform:"scale(1.2)"}}/>
                                    </span>
                                </div>
                                </div>:

                                (!item?.seen && item?.type=="event_attendee_change_by_admin") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                <div className="col-12">
                                    <div className="">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <h3 className="text-success fs-4 fw-bold me-2">Event Attendees</h3>
                                        </div>
                                        <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                </span>
                                                {item?.description && <div className="ms-3">
                                                    {item?.description}
                                                </div>}
                                        </div>
                                    </div>
                                    <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                        updateNotificationSeenStatus(item)
                                    }}>
                                        <RxCross1 style={{transform:"scale(1.2)"}}/>
                                    </span>
                                </div>
                                </div>:

                                (!item?.seen && item?.type=="events") ?
                                        <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                        <div className="col-8">
                                            <div className="">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    {item?.description && <h3 className="fs-5 fw-bold me-2">{item?.description}</h3>}
                                                    <div >
                                                        <img style={{width:"30px",height:"30px",borderRadius:"100%"}} src={item?.companyDetails?.company_logo?item?.companyDetails?.company_logo:BackboneLogo}/>
                                                    </div>
                                                </div>
                                                <div className="bg-white p-3 w-100 my-2" style={{borderRadius:"40px"}}>
                                                        <h3 className="text-primary fw-bold fs-5">{item?.eventDetails?.eventName} on {formatEventDate(item?.eventDetails?.eventDate)} </h3>
                                                        {/* <h4 className="fs-6 fw-bold">description</h4> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-4" style={{display:"flex",justifyContent:"",alignItems:"flex-end",flexDirection:"column"}}>
                                            <div className="mt-2">
                                                <div className="my-2 py-3 w-100">
                                                    <span className="ms-2 cursor-pointer"  style={{background:"blue",color:"white",padding:"10px 20px",borderRadius:"30px"}} onClick={()=>{
                                                        updateNotificationSeenStatus(item)
                                                        navigate("/upcoming-events")
                                                    }}>Event</span>
                                                </div>
                                            </div>
                                            <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                                updateNotificationSeenStatus(item)
                                            }}>
                                                <RxCross1 style={{transform:"scale(1.2)"}}/>
                                            </span>
                                        </div>

                                        </div>:
                                (!item?.seen && item?.type=="review") ?
                                        <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                        <div className="col-12">
                                            <div className="">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <h3 className="text-success fs-4 fw-bold me-2">Company Review</h3>
                                                </div>
                                                <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                        <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                        </span>
                                                        {item?.description && <div className="ms-3">
                                                            {item?.description}
                                                        </div>}
                                                </div>
                                            </div>
                                            <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                                updateNotificationSeenStatus(item)
                                            }}>
                                                <RxCross1 style={{transform:"scale(1.2)"}}/>
                                            </span>
                                        </div>
                                        </div>:
                                (!item?.seen && item?.type=="getInTouch") ?
                                    <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                    <div className="col-12">
                                        <div className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <h3 className="text-success fs-4 fw-bold me-2">Get in touch</h3>
                                            </div>
                                            <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                    <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                    </span>
                                                    {item?.description && <div className="ms-3">
                                                        {item?.description}
                                                    </div>}
                                            </div>
                                        </div>
                                        <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                                updateNotificationSeenStatus(item)
                                            }}>
                                            <RxCross1 style={{transform:"scale(1.2)"}}/>
                                        </span>
                                    </div>
                                    </div>:<div key={index}></div>
                    })
                }




                    {  notifications?.notificationList?.rows?.filter((item)=>item?.seen)?.length>0 &&  <div className="new_notification_container">
                            <span className="fw-bolder fs-3">Past</span>
                    </div>}


                {
                    notifications?.notificationList?.rows?.map((item,index)=>{
                        return   (item?.seen && item?.type=="backing") ? <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                        <div className="col-8">
                                            <div className="">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <div >
                                                        <img style={{width:"30px",height:"30px",borderRadius:"100%"}} src={item?.companyDetails?.company_logo?item?.companyDetails?.company_logo:BackboneLogo}/>
                                                    </div>
                                                    {item?.description && <h3 className="fs-4 fw-bold ms-2">{item?.description}</h3>}
                                                </div>
                                                {item?.backingDetails && <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                        <div className="">
                                                        {
                                                                item?.backingDetails?.interaction_type=="Met at an event & highly rate" &&  <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/9499/9499879.png"/>
                                                            }
                                                            {
                                                                item?.backingDetails?.interaction_type=="Partnered together & highly rate" &&  <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/16359/16359431.png"/>
                                                            }
                                                             {
                                                                item?.backingDetails?.interaction_type=="Outstanding Partnership Outcome" &&  <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/17437/17437670.png"/>
                                                            }
                                                            {
                                                                item?.backingDetails?.interaction_type=="Other: Enter Free Text (200 max)" &&  <img style={{width:"40px",height:"40px",borderRadius:"100%"}} src="https://cdn-icons-png.flaticon.com/512/12865/12865989.png"/>
                                                                
                                                            }
                                                        </div>
                                                        <h3 className="text-primary fw-bold fs-4 ms-4 text-center">
                                                            {
                                                                item?.backingDetails?.interaction_type=="Other: Enter Free Text (200 max)" ? item?.backingDetails?.free_text : item?.backingDetails?.interaction_type
                                                            }
                                                            
                                                        </h3>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="col-4" style={{display:"flex",justifyContent:"",alignItems:"flex-end",flexDirection:"column"}}>
                                            <div className="mt-2">
                                                <div>
                                                    <h3 className="text-success fw-bolder ms-auto text-end fs-4">+ {
                                                        bizBucksConfig?.find((item)=>item?.configKey=="back_a_business")?.configValue
                                                    } BizBucks</h3>
                                                </div>
                                                <div className="my-2 py-3 w-100">
                                                    <span className="cursor-pointer" style={{background:"blue",color:"white",padding:"5px 10px",borderRadius:"30px"}} onClick={()=>{
                                                        if(item?.backingDetails?.backer_company_id){
                                                            navigate(`/company/${item?.backingDetails?.backer_company_id}`)
                                                        }
                                                    }}>See Review</span>
                                                    <span className="ms-2 cursor-pointer"  style={{background:"blue",color:"white",padding:"5px 10px",borderRadius:"30px"}} onClick={()=>{
                                                        // dispatch(eventActions.setBackingCompanyId(item?.backingDetails?.backer_company_id))
                                                        setBackedBizData({
                                                            ...backedBizData, backed_id:item?.backingDetails?.backer_id,
                                                            backed_company_id:item?.backingDetails?.backer_company_id,
                                                        })
                                                        setVisible(true)
                                                    }}>Back Them</span>
                                                </div>
                                            </div>
                                            {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}>
                                                <RxCross1 style={{transform:"scale(1.2)"}}/>
                                            </span> */}
                                        </div>
                                 </div> :
                                (item?.seen && (item?.type=="podcast_confirm" || item?.type=="spotlight_confirm")) ?
                                    <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px",cursor:"pointer"}} onClick={()=>{
                                        navigate("/upcoming-events")
                                    }}>
                                            <div className="col-12">
                                                <div className="">
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <h3 className="fs-4 fw-bold me-2 text-success">
                                                        {
                                                                item?.type=="podcast_confirm" ? "Podcast Confirm" : "Spotlight Confirm"
                                                            }
                                                        </h3>
                                                    </div>
                                                    <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                            <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                            </span>
                                                            {item?.description && <div className="ms-3">
                                                            {item?.description}
                                                            </div>}
                                                    </div>
                                                </div>
                                                {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}>
                                                    <RxCross1 style={{transform:"scale(1.2)"}}/>
                                                </span> */}
                                            </div>
                                    </div>:
                                (item?.seen && (item?.type=="podcast_waitlist" || item?.type=="spotlight_waitlist")) ?
                                    <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                        <div className="col-12">
                                            <div className="">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <h3 className="fs-4 fw-bold me-2 text-warning">
                                                    {
                                                                item?.type=="podcast_waitlist" ? "Podcast Waitlist" : "Spotlight Waitlist"
                                                            }
                                                    </h3>
                                                </div>
                                                <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                        <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                        </span>
                                                        {item?.description && <div className="ms-3">
                                                            {item?.description}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}>
                                                <RxCross1 style={{transform:"scale(1.2)"}}/>
                                            </span> */}
                                        </div>
                                    </div>:
                                (item?.seen && (item?.type=="podcast_request" || item?.type=="spotlight_request")) ?
                                    <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                            <div className="col-12">
                                                <div className="">
                                                <div className="d-flex justify-content-start align-items-center">
                                                        <h3 className="fs-4 fw-bold me-2 text-primary">
                                                        {
                                                                item?.type=="podcast_request" ? "Podcast Request" : "Spotlight Request"
                                                            }
                                                        </h3>
                                                    </div>
                                                    <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                            <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                            </span>
                                                           {item?.description && <div className="ms-3">
                                                            {item?.description}
                                                            </div>}
                                                    </div>
                                                </div>
                                                {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}>
                                                    <RxCross1 style={{transform:"scale(1.2)"}}/>
                                                </span> */}
                                            </div>
                                    </div>:
                                (item?.seen && item?.type=="earn_reward") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px",cursor:"pointer"}} onClick={()=>{
                                    navigate("/dashboard")
                                }}>
                                        <div className="col-12">
                                            <div className="">
                                                <div className="d-flex justify-content-start align-items-center">
                                                    <h3 className="text-success fs-4 fw-bold me-2">BizBucks Earn</h3>
                                                </div>
                                                <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                        <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                        </span>
                                                       {item?.description && <div className="ms-3">
                                                        {item?.description}
                                                        </div>}
                                                </div>
                                            </div>
                                            {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}>
                                                <RxCross1 style={{transform:"scale(1.2)"}}/>
                                            </span> */}
                                        </div>
                                    </div>:
                                 (item?.seen && item?.type=="referFriend") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                    <div className="col-12">
                                        <div className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <h3 className="text-success fs-4 fw-bold me-2">Refer Freinds</h3>
                                            </div>
                                            <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                    <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                    </span>
                                                    {item?.description && <div className="ms-3">
                                                        {item?.description}
                                                    </div>}
                                            </div>
                                        </div>
                                        {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                            updateNotificationSeenStatus(item)
                                        }}>
                                            <RxCross1 style={{transform:"scale(1.2)"}}/>
                                        </span> */}
                                    </div>
                                </div>:
                                (item?.seen && item?.type=="subscriptionCredit") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                    <div className="col-12">
                                        <div className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <h3 className="text-success fs-4 fw-bold me-2">Subscription Credit</h3>
                                            </div>
                                            <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                    <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                    </span>
                                                    {item?.description && <div className="ms-3">
                                                        {item?.description}
                                                    </div>}
                                            </div>
                                        </div>
                                        {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                            updateNotificationSeenStatus(item)
                                        }}>
                                            <RxCross1 style={{transform:"scale(1.2)"}}/>
                                        </span> */}
                                    </div>
                                </div>:

                                (item?.seen && item?.type=="event_registration_update_by_admin") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                <div className="col-12">
                                    <div className="">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <h3 className="text-success fs-4 fw-bold me-2">Event Registration</h3>
                                        </div>
                                        <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                </span>
                                                {item?.description && <div className="ms-3">
                                                    {item?.description}
                                                </div>}
                                        </div>
                                    </div>
                                    {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                        updateNotificationSeenStatus(item)
                                    }}>
                                        <RxCross1 style={{transform:"scale(1.2)"}}/>
                                    </span> */}
                                </div>
                                </div>:

                                (item?.seen && item?.type=="event_attendee_change_by_admin") ?  <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                <div className="col-12">
                                    <div className="">
                                        <div className="d-flex justify-content-start align-items-center">
                                            <h3 className="text-success fs-4 fw-bold me-2">Event Attendees</h3>
                                        </div>
                                        <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                </span>
                                                {item?.description && <div className="ms-3">
                                                    {item?.description}
                                                </div>}
                                        </div>
                                    </div>
                                    {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}} onClick={()=>{
                                        updateNotificationSeenStatus(item)
                                    }}>
                                        <RxCross1 style={{transform:"scale(1.2)"}}/>
                                    </span> */}
                                </div>
                                </div>:
                                (item?.seen && item?.type=="events") ?
                                    <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                    <div className="col-8">
                                        <div className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                {item?.description && <h3 className="fs-5 fw-bold me-2">{item?.description}</h3>}
                                                <div >
                                                    <img style={{width:"30px",height:"30px",borderRadius:"100%"}} src={item?.companyDetails?.company_logo?item?.companyDetails?.company_logo:BackboneLogo}/>
                                                </div>
                                            </div>
                                            <div className="bg-white p-3 w-100 my-2" style={{borderRadius:"40px"}}>
                                                    <h3 className="text-primary fw-bold fs-5">{item?.eventDetails?.eventName} on {formatEventDate(item?.eventDetails?.eventDate)} </h3>
                                                    {/* <h4 className="fs-6 fw-bold">description</h4> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4" style={{display:"flex",justifyContent:"",alignItems:"flex-end",flexDirection:"column"}}>
                                        <div className="mt-2">
                                            <div className="my-2 py-3 w-100">
                                                <span className="ms-2 cursor-pointer"  style={{background:"blue",color:"white",padding:"10px 20px",borderRadius:"30px"}} onClick={()=>{
                                                    navigate("/upcoming-events")
                                                }}>Event</span>
                                            </div>
                                        </div>
                                        {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}>
                                            <RxCross1 style={{transform:"scale(1.2)"}}/>
                                        </span> */}
                                    </div>

                                    </div>:
                                (item?.seen && item?.type=="review") ?
                                    <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                    <div className="col-12">
                                        <div className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <h3 className="text-success fs-4 fw-bold me-2">Company Review</h3>
                                            </div>
                                            <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                    <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                    </span>
                                                    {item?.description && <div className="ms-3">
                                                        {item?.description}
                                                    </div>}
                                            </div>
                                        </div>
                                        {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}>
                                            <RxCross1 style={{transform:"scale(1.2)"}}/>
                                        </span> */}
                                    </div>
                                    </div>:
                                (item?.seen && item?.type=="getInTouch") ?
                                    <div key={index} className="row my-3" style={{position:"relative",background:"#f2f2f2",borderRadius:"15px",padding:"15px",maxWidth:"780px"}}>
                                    <div className="col-12">
                                        <div className="">
                                            <div className="d-flex justify-content-start align-items-center">
                                                <h3 className="text-success fs-4 fw-bold me-2">Get in touch</h3>
                                            </div>
                                            <div className="bg-white p-3 w-100 my-2 d-flex justify-content-start align-items-center" style={{borderRadius:"40px"}}>
                                                    <span><FaInfoCircle style={{fontSize: "30px"}} />
                                                    </span>
                                                    {item?.description && <div className="ms-3">
                                                        {item?.description}
                                                    </div>}
                                            </div>
                                        </div>
                                        {/* <span style={{position:"absolute",top:"10px",right:"10px",cursor:"pointer"}}>
                                            <RxCross1 style={{transform:"scale(1.2)"}}/>
                                        </span> */}
                                    </div>
                                    </div>:<div key={index}></div>
                    })
                }

                   { notifications?.notificationList?.rows?.length>0  && <div className="mx-auto text-center my-4 w-100">
                        <ul className="pagination">
                            
                            <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                // if(activePage>1){
                                //     setActivePage((pre)=>pre-1)
                                // }
                                if(initialState>0){
                                    setEndState(initialState)
                                    setInitialState((pre)=>pre-10)
                                }
                            }}>
                                <img  style={{width:"18px",margin:"3px"}} src="https://cdn-icons-png.flaticon.com/512/11502/11502464.png"/>
                            </span></li>
                            {
                                Array.from({ length:Math.ceil((notifications?.notificationList?.count)/10) }, (_, i) => i + 1)?.slice(initialState,endState)?.map((item,index)=>{
                                return <li key={index} className="page-item cursor-pointer"><span className={activePage==item?`page-link text-primary fw-bold`:`page-link text-dark fw-bold`} onClick={()=>{
                                    console.log(item)
                                    if(activePage!=item){
                                        setActivePage(item)
                                    }
                                }}>{item}</span></li>
                            })
                            }
                            <li className="page-item cursor-pointer"><span className="page-link" onClick={()=>{
                                // if(activePage<Math.ceil((notifications?.notificationList?.count)/10)){
                                //     setActivePage((pre)=>pre+1)
                                // }
                                if(endState<(notifications?.notificationList?.count/10)){
                                    setInitialState(endState)
                                    setEndState((pre)=>pre+10)
                                }
                                
                            }}>
                                <img style={{width:"24px"}} src="https://cdn-icons-png.flaticon.com/512/2722/2722998.png"/></span></li>
                        </ul>
                    </div>}



                    { notifications?.notificationList?.rows?.length<=0 && <div className="d-flex justify-content-center align-items-center" style={{width:"100%",height:"60vh"}}>
                          <h1 className="fw-bold fs-4">No Notifications Available</h1>
                        </div>}




                 <Modal size="xl" backdrop className="mb-0" active={visible} toggler={()=>{
                    setVisible(false)
                 }}>
                        <ModalBody className="mb-0">
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-12'>
                                    <div className='my-3'>
                                            <select className='form-control p-2' value={backedBizData?.interaction_type} onChange={(e)=>{
                                                setBackedBizData({
                                                    ...backedBizData,interaction_type:e?.target?.value
                                                })
                                            }}>
                                                <option value="" disabled>Interaction</option>
                                                <option value="Met at an event & highly rate">Met at an event & highly rate</option>
                                                <option value="Partnered together & highly rate">Partnered together & highly rate</option>
                                                <option value="Outstanding Partnership Outcome">Outstanding Partnership Outcome</option>
                                                <option value="Other: Enter Free Text (200 max)">Other: Enter Free Text (200 max)</option>
                                            </select>
                                            </div>

                                            {backedBizData?.interaction_type?.trim()=="Other: Enter Free Text (200 max)" && <div>
                                                <textarea rows="2" className='form-control p-2' placeholder='Enter Text' value={backedBizData?.free_text} onChange={(e)=>{
                                                     if(e?.target?.value?.length<200){
                                                        setBackedBizData({
                                                            ...backedBizData,free_text:e?.target?.value
                                                        })
                                                     }
                                                    
                                                }}/>
                                            </div>}
                                            <div className='mx-auto my-2 w-100 text-end'>
                                            <button className='post_btn' onClick={()=>{
                                                // setVisible(true)4
                                                handleSubmitBackedBiz()
                                            }}>Post</button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                 </Modal>



                 <Modal size="xl" backdrop className="mb-0" active={visible2} toggler={()=>{
                    setVisible2(false)
                 }}>
                        <ModalBody className="mb-0">
                            <div className='container-fluid'>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='row mb-0' style={{border:"2px solid #03b0fb",borderRadius:"10px",padding:"10px 15px"}}>
                                            <div className='col-10'>
                                                <div className=''>
                                                    <h3 className='fs-4 fw-bolder' style={{color:"#162452"}}>You have backed !</h3>
                                                    <p className='fw-bold my-2 w-100' style={{fontSize:"12px",color:"#162452"}}>Thank you for backing a businesses</p>
                                                </div>
                                                <div className='d-flex justify-content-between align-items-center' style={{background:"#ebffe4",padding:"10px",borderRadius:"15px"}}>
                                                    <div className='d-flex justify-content-start align-items-center'>
                                                            <span style={{fontSize:"45px",color:"#162452",fontWeight:"900"}}>+</span>
                                                            <span className='ms-2' style={{color:"#ffd79c",fontSize:"50px",fontWeight:"900"}}>
                                                            {
                                                                bizBucksConfig?.find((item)=>item?.configKey=="back_a_business")?.configValue
                                                            }
                                                            </span>
                                                    </div>
                                                    <div>
                                                        <h3 className='fs-6 fw-bolder text-center pe-5'>BizBucks credited to your <br/> account</h3>
                                                    </div>
                                                </div>

                                                <div className='my-2 w-100'>
                                                    <p style={{fontSize:"10px"}}>Your backing will be posted on the Dashboard under “Success Stories”</p>
                                                    <p style={{fontSize:"10px"}}>To edit or delete, go to “My Backings” on your profile</p>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div style={{display:"flex",height:"100%",justifyContent:"center",alignItems:"center"}}>
                                                    <img  src={triangle} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                 </Modal>



            </div>
        </div>
    );
};

export default Notifications;
