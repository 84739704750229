import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ChipInput from "material-ui-chip-input";

import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";

import "./Tags.css";

import { useDispatch, useSelector } from "react-redux";
import { deletePrefernceAction } from "../../../data/reducers/details/details";
import { useToast } from "@chakra-ui/react";
import { addPrefernce } from "../../../data/services/profile/profile";

const CompanyPrefernce = ({ isMine,findCompanyPrefernce, serv }) => {
    const [tags, setTags] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [addTags, setAddTags] = useState([]);
    const [deleteTags, setDeleteTags] = useState([]);
    const toast = useToast();
    const { success, message } = useSelector((state) => state.profile);
    const { myCompany } = useSelector((state) => state.content);

    const dispatch = useDispatch();

    useEffect(()=>{
       if(serv){
        setTags(serv?.map((item)=>item?.industry))
       }
    },[serv])

    const handleAddChip = async (tag) => {
        const tagsHistoryList = [...addTags, tag];
        setAddTags(tagsHistoryList);
        setTags([...tags, tag]);
    };

    const handleDeleteChip = (chipToDelete) => {

        setTags(tags.filter((tag) => tag !== chipToDelete));
        const tagsDeleteList = [...deleteTags, chipToDelete];
        setDeleteTags(tagsDeleteList);
    };

    const handleTags = () => {
        try{
            setShowModal(false);
            if (deleteTags.length > 0) {
                deleteTags?.map((chipToDelete) => {
                    let obj = serv?.find((o) => o.industry === chipToDelete);
                    dispatch(deletePrefernceAction(obj.id));
                })
                setTimeout(() => {
                    findCompanyPrefernce()
                }, 1000);
                setDeleteTags([]);
            } else {
                addTags?.map(async (tag) => {
                    let data=await (addPrefernce(myCompany?.id,tag));
                    findCompanyPrefernce()
                });
                setAddTags([]);
            }
            toast({
                title: "Success",
                description: "Updated Preference",
                isClosable: true,
                status: "success",
                position: "top-right",
                duration: 2000,
            });
        }catch(err){
            
        }
        
    };

    useEffect(() => {
        serv?.map((item, i) => {
            tags.push(item.service_name);
        });
    }, []);

    return (
        <>
            <div className="profile-tags-section">
                <h1>My Prefernce</h1>
                <div className="profile-tags">
                    {serv?.map((service, ind) => (
                        <div className="profile-tags-each" key={ind}>
                            {service?.industry}
                            {isMine && (
                                <AiOutlineCloseCircle
                                    className="tags-close-icon"
                                    onClick={() =>
                                        handleDeleteChip(service?.id)
                                    }
                                />
                            )}
                            {isMine && (
                                <AiOutlineCloseCircle
                                    onClick={() => {
                                        setShowModal(true);
                                    }}
                                    className="tags-close-icon"
                                />
                            )}
                        </div>
                    ))}
                    {serv?.length < 19 && isMine ? (
                        <div
                            className="profile-tags-add"
                            onClick={() => setShowModal(true)}
                        >
                            Add Prefernce
                        </div>
                    ) : (
                        isMine && (
                            <div className="profile-tags-disable">Add Prefernce</div>
                        )
                    )}
                </div>
            </div>
            <div className="profile-modal modal-wrapper">
                <Modal
                    size="regular"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <h1 className="modal-head">
                            Add Preference <span>(upto 10)</span>
                        </h1>
                        <div className="mt-4 mb-3">
                            <ChipInput
                                style={{ margin: "0 0.5rem" }}
                                required
                                blurBehavior="add"
                                value={tags}
                                onAdd={(chip) => {
                                    if (tags.length < 10) {
                                        handleAddChip(chip);
                                    } else {
                                    }
                                }}
                                fullWidth
                                onDelete={(chip) => handleDeleteChip(chip)}
                                //label="Add Tags"
                                variant="outlined"
                            />
                        </div>
                    </ModalBody>
                    <div className="d-flex justify-content-between">
                        <div className="close-button">
                            <Button
                                color="red"
                                buttonType="link"
                                onClick={(e) => setShowModal(false)}
                                ripple="dark"
                            >
                                Close
                            </Button>
                        </div>
                        <div className="save-button">
                            <Button
                                color="green"
                                onClick={(e) => handleTags()}
                                ripple="light"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default CompanyPrefernce;
