import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import ChipInput from "material-ui-chip-input";

import Modal from "@material-tailwind/react/Modal";
import ModalBody from "@material-tailwind/react/ModalBody";
import Button from "@material-tailwind/react/Button";

import "./Tags.css";
import {
    addServicesAction,
    getServicesAction,
    profileActions,
} from "../../../data/reducers/profile/profile";
import { useDispatch, useSelector } from "react-redux";
import { getMyCompanyAction } from "../../../data/reducers/home/content";
import { deleteServicesAction } from "../../../data/reducers/details/details";
import { useToast } from "@chakra-ui/react";

const Tags = ({ isMine, industry, serv }) => {
    const [tags, setTags] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [addTags, setAddTags] = useState([]);
    const [deleteTags, setDeleteTags] = useState([]);
    const toast = useToast();
    const { success, message } = useSelector((state) => state.profile);
    const dispatch = useDispatch();

    useEffect(() => {
        if (success && message) {
            dispatch(profileActions.reset());
            dispatch(getServicesAction());
        }
        dispatch(profileActions.reset());
    }, [success, toast, message]);

    const handleAddChip = async (tag) => {
        const tagsHistoryList = [...addTags, tag];
        setAddTags(tagsHistoryList);
        setTags([...tags, tag]);
    };

    const handleDeleteChip = (chipToDelete) => {
        setTags(tags.filter((tag) => tag !== chipToDelete));
        const tagsDeleteList = [...deleteTags, chipToDelete];
        setDeleteTags(tagsDeleteList);
    };

    const handleTags = () => {
        setShowModal(false);
        if (deleteTags.length > 0) {
            deleteTags?.map((chipToDelete) => {
                let obj = serv?.find((o) => o.service_name === chipToDelete);
                dispatch(deleteServicesAction(obj.id));
                setTimeout(() => {
                    dispatch(getMyCompanyAction());
                }, 1000);
            });
            setDeleteTags([]);
        } else {
            addTags?.map((tag) => {
                var urlencoded = new URLSearchParams();
                urlencoded.append("service_name", tag);
                dispatch(addServicesAction(urlencoded));
            });
            setAddTags([]);
        }

        toast({
            title: "Success",
            description: "Updated tags",
            isClosable: true,
            status: "success",
            position: "top-right",
            duration: 2000,
        });
    };

    useEffect(() => {
        serv?.map((item, i) => {
            tags.push(item.service_name);
        });
    }, []);

    return (
        <>
            <div className="profile-tags-section">
                <h1>Tags</h1>
                <div className="profile-tags">
                    <div className="profile-tags-each">
                        {industry?.industry_name}
                        {isMine && (
                            <AiOutlineCloseCircle className="tags-close-icon" />
                        )}
                    </div>
                    {serv?.map((service, ind) => (
                        <div className="profile-tags-each" key={ind}>
                            {service?.service_name}
                            {isMine && (
                                <AiOutlineCloseCircle
                                    className="tags-close-icon"
                                    onClick={() =>
                                        handleDeleteChip(service?.id)
                                    }
                                />
                            )}
                            {isMine && (
                                <AiOutlineCloseCircle
                                    onClick={() => {
                                        setShowModal(true);
                                    }}
                                    className="tags-close-icon"
                                />
                            )}
                        </div>
                    ))}
                    {serv?.length < 19 && isMine ? (
                        <div
                            className="profile-tags-add"
                            onClick={() => setShowModal(true)}
                        >
                            Add Tag
                        </div>
                    ) : (
                        isMine && (
                            <div className="profile-tags-disable">Add Tag</div>
                        )
                    )}
                </div>
            </div>
            <div className="profile-modal modal-wrapper">
                <Modal
                    size="regular"
                    active={showModal}
                    toggler={() => setShowModal(false)}
                    className="profile-tailwind-modal"
                >
                    <ModalBody>
                        <h1 className="modal-head">
                            Add Tags <span>(upto 10)</span>
                        </h1>
                        <div className="mt-4 mb-3">
                            <ChipInput
                                style={{ margin: "0 0.5rem" }}
                                required
                                blurBehavior="add"
                                value={tags}
                                onAdd={(chip) => {
                                    if (tags.length < 10) {
                                        handleAddChip(chip);
                                    } else {
                                        console.log("juihfuih");
                                    }
                                }}
                                fullWidth
                                onDelete={(chip) => handleDeleteChip(chip)}
                                //label="Add Tags"
                                variant="outlined"
                            />
                        </div>
                    </ModalBody>
                    <div className="d-flex justify-content-between">
                        <div className="close-button">
                            <Button
                                color="red"
                                buttonType="link"
                                onClick={(e) => setShowModal(false)}
                                ripple="dark"
                            >
                                Close
                            </Button>
                        </div>
                        <div className="save-button">
                            <Button
                                color="green"
                                onClick={(e) => handleTags()}
                                ripple="light"
                            >
                                Save Changes
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </>
    );
};

export default Tags;
